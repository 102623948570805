<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header></nav-header>

    <div class="app-container home">
      <div class="box">
        <div class="box-left">
          <div class="box-left-tr">
            <img src="@/assets/image/home/home1.png" alt="" />
          </div>
          <div class="box-left-br">
            <img src="@/assets/image/home/home6.png" alt="" />
          </div>

          <p class="box-left-p">✦ 模擬教育部指導 全民英檢GEPT題型</p>
          <h2 class="box-left-h2">
            第<span>{{ dataForm.session_id }}</span>屆JEPT<template v-if="dataForm.type == 2">仿真模擬考</template><template v-if="dataForm.type == 3">練習模擬考</template>
          </h2>
          <h2 class="box-left-h2">開放登入時間為</h2>
          <div class="box-left-time">
            <div class="time">
              <div class="time-num">{{ examYSta }}</div>
              <div class="time-zh">時間</div>
              <div class="time-line"></div>
            </div>
            <div class="date">
              <span>{{ examMSta }} / {{ examDSta }}</span>
              {{ examHSta }}:{{ examSSta }}
            </div>
            <img class="img" src="@/assets/image/home/home2.png" alt="至" />
            <div class="date">
              <span>{{ examMEnd }} / {{ examDEnd }}</span>
              {{ examHEnd }}:{{ examSEnd }}
            </div>
          </div>
          <div class="box-left-count" v-if="examStatus != 3">
            <div v-if="examStatus == 1">
              <p>距離考試時間尚有</p>
              <span>{{day}}</span>天
              <span>{{hour}}</span>時
              <span>{{min}}</span>分
              <span>{{second}}</span>秒
            </div>
          </div>
          <div class="box-left-button" v-if="examStatus != 3">
            <div class="button1" v-if="examStatus == 1">考試尚未開始</div>
            <div class="button2" v-if="examStatus == 2" @click="startTest">
              開始考試
              <img src="@/assets/image/icon/icon-arrow.png" alt="" />
            </div>
          </div>
          <div class="box-left-warn" v-if="examStatus == 3">
            <p>非常抱歉您已錯過考試時間，</p>
            <p>無法再進入考場參加考試。</p>
            <p>根據 JEPT 報名規則，</p>
            <p>考場僅開放 30 分鐘登入時間。</p>
            <p>錯過將無法參加考試。</p>
          </div>
          <div class="box-left-notice">
            若有時間，您可以再次詳閱
            <span>「</span>
            <i @click="toPrivacy">應考須知</i>
            <span>」</span>
          </div>
        </div>
        <div class="box-right">
          <img src="@/assets/image/home/home4.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import { examinationRoomInfo, examinationStartTest } from "@/request/api"
export default {
  name: "Home",
  data() {
    return {
      // 考試狀態 1==考試未開始  2==考試進行中 3==考試已結束
      examStatus: 1,
      // 提示語狀態
      titleStatus: false,
      // 考场信息
      dataForm: {},
      // 天
      day: '00',
      // 時
      hour: '00',
      // 分
      min: '00',
      // 秒
      second: '00',
      // 定時器
      promiseTimer: null,
      // 倒计时 秒数
      countDown: 0,

      // 考场开放时间 年
      examYSta: 2022,
      // 考场开放时间 月-开始
      examMSta: '1',
      // 考场开放时间 日-开始
      examDSta: '01',
      // 考场开放时间 时-开始
      examHSta: '09',
      // 考场开放时间 分-开始
      examSSta: '00',
      // 考场开放时间 月-结束
      examMEnd: '1',
      // 考场开放时间 日-结束
      examDEnd: '01',
      // 考场开放时间 时-结束
      examHEnd: '09',
      // 考场开放时间 分-结束
      examSEnd: '00',
    };
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取考场信息
    getData() {
      examinationRoomInfo().then(res => {
        // 考场信息
        this.dataForm = res.data

        // 考试时间 开始
        const exam_s_time = new Date(res.data.exam_s_time).getTime()
        // 考试时间 结束
        const exam_e_time = new Date(res.data.exam_e_time).getTime()
        // 服务器当前时间
        const now_time = new Date(res.data.now_time).getTime()
        // 根据时间判断考试状态
        if (now_time < exam_s_time) { // 当前时间小于开始时间  按钮状态变为未开始 并调用倒计时
          this.examStatus = 1
          // 倒计时赋值
          this.countDown = (exam_s_time - now_time) / 1000
          // 倒计时函数
          this.countTime()
        } else if (now_time >= exam_s_time && now_time <= exam_e_time) { // 当前时间大于开始时间并且小于结束时间  按钮状态变为进行中
          this.examStatus = 2
        } else if (now_time > exam_e_time) { // 当前时间大于结束时间  按钮状态变为已结束
          this.examStatus = 3
        }

        // 展示的考试时间处理
        const examStaTime = new Date(res.data.exam_s_time)
        const examEndTime = new Date(res.data.exam_e_time)
        this.examYSta = examStaTime.getFullYear()
        this.examMSta = examStaTime.getMonth() + 1
        this.examDSta = examStaTime.getDate().toString().padStart(2, '0')
        this.examHSta = examStaTime.getHours().toString().padStart(2, '0')
        this.examSSta = examStaTime.getMinutes().toString().padStart(2, '0')
        this.examMEnd = examEndTime.getMonth() + 1
        this.examDEnd = examEndTime.getDate().toString().padStart(2, '0')
        this.examHEnd = examEndTime.getHours().toString().padStart(2, '0')
        this.examSEnd = examEndTime.getMinutes().toString().padStart(2, '0')
      })
    },
    // 應考須知
    toPrivacy() {
      if (this.dataForm.notes_for_examination) {
        window.open(this.dataForm.notes_for_examination)
      } else {
        this.$message({
          message: '暫無應考須知鏈接！',
          type: 'warning'
        });
      }
    },
    // 開始考試
    startTest() {
      examinationStartTest().then(() => {
        
      }).finally(() => {
        this.$router.push({
          path: '/privacyDetection'
        })
      })
    },
    // 倒计时
    countTime () {
      // 当前时间
      this.countDown--
      // 定义变量 d,h,m,s保存倒计时的时间
      if (this.countDown >= 0) {
        // 天
        let d = Math.floor(this.countDown / 60 / 60 / 24)
        this.day = d.toString().padStart(2, '0')
        // 时
        let h = Math.floor(this.countDown / 60 / 60 % 24)
        this.hour = h.toString().padStart(2, '0')
        // 分
        let m = Math.floor(this.countDown / 60 % 60)
        this.min = m.toString().padStart(2, '0')
        // 秒
        let s = Math.floor(this.countDown % 60)
        this.second = s.toString().padStart(2, '0')
      } else {
        this.day = '00'
        this.hour = '00'
        this.min = '00'
        this.second = '00'
      }
      // 等于0的时候不调用
      if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.second) === 0) {
        return this.examStatus = 2
      } else {
      // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.home {
  .box {
    margin: 0 auto;
    padding: 90px 0;
    width: 1120px;
    position: relative;
    .box-left {
      width: 700px;
      background-image: url(../../assets/image/home/home3.png);
      background-repeat: no-repeat;
      background-size: 100%;
      position: relative;
      .box-left-tr {
        position: absolute;
        top: -20px;
        right: 225px;
        width: 93px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-left-br {
        position: absolute;
        bottom: 20px;
        right: -10px;
        width: 53px;
        height: 53px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-left-p {
        margin-bottom: 20px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 29px;
        letter-spacing: 0.01em;
        color: #989898;
      }
      .box-left-h2 {
        margin: 10px 0;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 68px;
        letter-spacing: 0.01em;
        color: #333333;
        span {
          font-weight: 600;
          color: #0C4FA2;
        }
      }
      .box-left-time {
        padding: 40px 0 0 83px;
        display: flex;
        align-items: center;
        position: relative;
        .time {
          position: absolute;
          bottom: 0;
          left: 10px;
          display: flex;
          .time-num {
            writing-mode: vertical-lr;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 30px;
            color: #0C4FA2;
          }
          .time-zh {
            writing-mode: vertical-lr;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: #0C4FA2;
          }
          .time-line {
            margin-left: 3px;
            transform: rotate(20deg);
            width: 1px;
            height: 70px;
            background: #0C4FA2;
          }
        }
        .date {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 50px;
          line-height: 67px;
          color: #0C4FA2;
          position: relative;
          span {
            position: absolute;
            left: 16px;
            top: -16px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: #0C4FA2;
          }
        }
        .img {
          width: 17px;
          height: 17px;
          margin: 0 10px;
        }
      }
      .box-left-count {
        width: 500px;
        margin: 40px 0;
        height: 43px;
        div {
          display: flex;
          align-items: center;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #333333;
          p {
            margin-right: 10px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 29px;
            color: #333333;
          }
          span {
            margin: 0 5px;
            min-width: 43px;
            height: 43px;
            background: #CE3C36;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #FFFFFF;
          }
        }
      }
      .box-left-button {
        user-select: none;
        width: 320px;
        height: 76px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        color: #FFFFFF;
        .button1 {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(12, 79, 162, 0.5);
          border-radius: 12px;
        }
        .button2 {
          cursor: pointer;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #0C4FA2;
          border-radius: 12px;
          img {
            margin-left: 10px;
            width: 30px;
            height: 30px;
          }
        }
      }
      .box-left-notice {
        margin-top: 20px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #989898;
        span {
          font-weight: bold;
          color: #000000;
        }
        i {
          user-select: none;
          cursor: pointer;
          font-style: normal;
          color: #0C4FA2;
        }
      }
      .box-left-warn {
        margin-top: 15px;
        width: 290px;
        padding: 20px 32px;
        background: #FDDCDA;
        border: 0.5px solid #EB2E26;
        border-radius: 8px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        color: #EB2E26;
      }
    }
    .box-right {
      position: absolute;
      top: 75px;
      right: -100px;
      width: 535px;
      height: 546px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>